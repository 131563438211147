

















































import { Component, Vue, PropSync, Prop, Watch } from "vue-property-decorator";
import { getCompanyList,saveCompany,getCompanyDetail,deleteCompany,editCompany } from "@/serve/medicalAppointment/plan";

@Component({
  name: "company",
})
export default class Company extends Vue {
  @PropSync("isShow", { type: Boolean }) showDialog!: boolean;
  @Prop() row!: any;
  @Watch("row", { immediate: true, deep: true })
  currentRow(newVal: any) {
    this.currentRow = newVal;
    if(newVal.id){
      this.getList(newVal.id)
    }
  }
  private comForm: any = [
    // {
    //   companyId: "0",
    //   companyName: "",
    //   id: 0,
    //   number: 0,
    //   surplus: "",
    // },
  ];
  private mounted() {
    this.fetchCompany();
  }
  private companyList: any = [];
  // 获取单位列表
  private fetchCompany() {
    getCompanyList({
      tenantId: sessionStorage.getItem("tenant_id"),
      size: 999,
    }).then((res: any) => {
      this.companyList = res.records;
      // this.companyList.unshift({
      //   id: "0",
      //   name: "不限",
      // });
    });
  }
  private saveItem(item: any){
    if(!item.companyId){
      this.$message.warning("请填写完整表单")
      return
    }
    const params: any = {
      scheduleId:this.row.id,
      number:item.number*1,
      companyId:item.companyId
    }
    if(!item.id){
    saveCompany(params).then(res=>{
      if(res.code===200){
        this.$message.success("操作成功！")
        this.getList(this.row.id)
      }
    })
    }else{
      params.ids = [item.id]
      editCompany(params).then(res=>{
      if(res.code===200){
        this.$message.success("操作成功！")
        this.getList(this.row.id)
      }
      })
    }
  }
  private getList(id: string|number){
    getCompanyDetail({
      scheduleId:id
    }).then((res: any)=>{
      this.comForm = []
      this.comForm = res.data
    })
  }
  private deleteItem(item: any,index: number){
    if(item.id){
      // 发送删除的接口
      deleteCompany([item.id]).then((res: any)=>{
      if(res.code===200){
        this.$message.success("操作成功！")
        this.getList(this.row.id)
      }
      })
    }else{
      // 还没有保存上，那就直接删除
      this.comForm.splice(index,1)
    }
  }
  private addNew() {
    this.comForm.push( {
      companyId: "",
      companyName: "",
      id: 0,
      number: 0,
      surplus: "",
    })
  }
}
