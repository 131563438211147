import request from "../../utils/request";
interface Item<T> {
  checkItemName?: T;
  checkItemType?: T;
  diseaseId?: T;
  size: number;
  current: number;
}
//获取预约数据
export function getSchedule(data: Item<string>): Promise<any> {
  return request({
    method: "GET",
    url: "manage/appointmentSchedule",
    type: "params",
    data
  });
}
// 新增修改
export function saveItem(data: any) {
  return request({
    url: "manage/appointmentSchedule/save",
    method: "POST",
    data: data
  });
}
// 设置状态
export function updateStatus(data: any) {
  return request({
    url: "manage/appointmentSchedule/updateStatus",
    method: "PUT",
    data: data
  });
}
// 删除
export function deleteBatch(data: any) {
  return request({
    url: "manage/appointmentSchedule/deleteBatch",
    method: "DELETE",
    data: data
  });
}
// 设置号源
export function updateBatch(data: any) {
  return request({
    url: "manage/appointmentSchedule/updateBatch",
    method: "PUT",
    data: data
  });
}
/** 根据点击预约订单获取订单列表 */
export function getOrderDetail(id: number) {
  return request({
    method: "GET",
    url: "manage/checkAppoint/" + id
  });
}
/** 套餐分页列表*/
export function packageList(data: any) {
  return request({
    method: "GET",
    url: "manage/appointmentSchedule/packageList",
    data,
    type: "params"
  });
}

// 获取单位列表
export function getCompanyList(data: any) {
  return request({
    method: "GET",
    url: "manage/appointmentSchedule/companyList",
    data,
    type: "params"
  });
}


// 新增
export function saveCompany(data: any) {
  return request({
    url: "manage/scheduleCompany",
    method: "POST",
    data: data
  });
}

// 修改
export function editCompany(data: any) {
  return request({
    url: "manage/scheduleCompany",
    method: "PUT",
    data: data
  });
}

// 获取公司限制人数
export function getCompanyDetail(data: any) {
  return request({
    method: "GET",
    url: "manage/scheduleCompany",
    data,
    type: "params"
  });
}

// 删除单位限制
export function deleteCompany(data: any) {
  return request({
    url: "manage/scheduleCompany",
    method: "DELETE",
    data: data
  });
}


//新增时间段

export function addTime(data: any) {
  return request({
    url: "manage/scheduleTime",
    method: "POST",
    data: data
  });
}

//修改时间段

export function updataTime(data: any) {
  return request({
    url: "manage/scheduleTime",
    method: "PUT",
    data: data
  });
}

//查询时间段

export function selectTime(data: any) {
  return request({
    url: "manage/scheduleTime",
    method: "GET",
    data: data,
    type: "params"
  });
}

//删除时间段

export function deleteTime(data: any) {
  return request({
    url: "manage/scheduleTime",
    method: "DELETE",
    data: data
  });
}
