






















































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import {
  getSchedule,
  saveItem,
  updateStatus,
  updateBatch,
  deleteBatch,
  packageList,
  getCompanyList,
  addTime,
  updataTime,
  selectTime,
  deleteTime
} from "@/serve/medicalAppointment/plan";
import company from './company.vue'
@Component({
  name: "Plan",
  components: { company }
})
export default class OrderQuery extends Vue {
  public ruleForm = {
    // id:0,
    num: "",
    startDate: "",
    endDate: "",
    isEnable: 1,
    packageId: 0,
    personnelLevel: "不限",
    hospital: "A",
    companyList: []
  };
  public form = {
    checkAppointPayStatus: "",
    startDate: "",
    endDate: "",
    tenantId: ""
  };
  public page = {
    current: 1,
    size: 10
  };
  //套餐选项
  public optionList: any = [];
  public healthDate = "";
  public orderData = [];
  public total = 0;
  private setNumVisible = false;
  private setStatusVisible = false;
  private personnelLevelArr = ["不限", "贵宾体检", "普通体检"];
  private editStartOptions = {
    disabledDate: (time: any) => {
      if (!this.ruleForm.endDate) {
        return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
      } else {
        return time.getTime() >= new Date(this.ruleForm.endDate);
      }
    }
  };
  private editStopOptions = {
    disabledDate: (time: any) => {
      return (
        time.getTime() < new Date(this.ruleForm.startDate) ||
        time.getTime() < new Date(1970 - 1 - 1).getTime() //禁止选择1970年以前的日期
      );
    }
  };
  private status = 1;
  private batchNum = 25;
  private dialogVisible = false;
  private tableLoading = false;
  private multipleSelection = [];

  @Watch("healthDate")
  changeDate(val: string[]) {
    if (val) {
      this.form.startDate = val[0];
      this.form.endDate = val[1];
    } else {
      this.form.startDate = "";
      this.form.endDate = "";
    }
  }

  public checkNumber = (rule: any, value: any, callback: any) => {
    if (!value) {
      return callback(new Error("不能为空"));
    }
    if (!Number.isInteger(value * 1)) {
      callback(new Error("请输入数值"));
    } else {
      callback();
    }
  };

  public rules = {
    num: [
      { required: true, message: "请输入人数", trigger: "blur" },
      { validator: this.checkNumber, trigger: "blur" }
    ],
    startDate: [
      {
        type: "string",
        required: true,
        message: "请选择开始日期",
        trigger: "change"
      }
    ],
    endDate: [
      {
        type: "string",
        required: true,
        message: "请选择结束日期",
        trigger: "change"
      }
    ],
    personnelLevel: [
      {
        type: "string",
        required: true,
        message: "请选择人员等级",
        trigger: "change"
      }
    ]
    // packageId: [
    //   { type: 'array', required: true, message: '请选择套餐种类', trigger: 'change' }
    // ],
  };

  public created() {
    this.getorderData();
  }

  public mounted() {
    this.fetchPackage()
    this.fetchCompany()
  }
  private fetchPackage() {
    packageList({ tenantId: 2987, size: 999 }).then(res => {
      this.optionList = res.records;
      this.optionList.unshift({
        id: 0,
        name: "不限"
      });
    });
  }


  // 单位列表
  // 获取单位列表
  public companyList: any = [];
  private fetchCompany() {
    getCompanyList({ tenantId: sessionStorage.getItem("tenant_id"), size: 999 }).then((res: any) => {
      this.companyList = res.records;
      // this.companyList.unshift({
      //   id: "0",
      //   name: "不限"
      // });
    });
  }
  // 删除套餐
  private deleteCom(item: any, index: number) {
    this.ruleForm.companyList.splice(index, 1)
  }
  // 新增一行
  private addNewLine() {
    (this.ruleForm.companyList as any).push({
      key: new Date().getTime(),
      compantId: "",
      number: 0
    })
  }
  public companyShow = false
  private currentRow = {}
  private viewCompany(row: any) {
    this.currentRow = { ...row }
    this.companyShow = true
  }
  private shangeShow(e: boolean) {
    this.companyShow = e
  }
  //获取预约表数据
  public async getorderData(): Promise<void> {
    const dataItem = { ...this.page, ...this.form };
    this.tableLoading = true;
    const res = await getSchedule(this.$changNull(dataItem));
    this.tableLoading = false;
    this.orderData = res.data.records;
    this.total = res.data.total;
  }

  //点击每页显示的条数触发获取数据
  public handleSizeChange(val: any): void {
    this.page.size = val;
    this.getorderData();
  }

  //点击第N页触发获取数据
  public handleCurrentChange(val: any): void {
    this.page.current = val;
    this.getorderData();
  }

  // 重置功能
  public resetForm() {
    this.form = {
      checkAppointPayStatus: "",
      startDate: "",
      endDate: "",

      tenantId: ""
    };
    this.page = {
      current: 1,
      size: 10
    };
    this.getorderData();
  }

  public handleSelectionChange(arr: any) {
    this.multipleSelection = arr;
  }

  private switchChange(row: any) {
    this.updateStatusByIds([row.id], row.isEnable);
  }

  private updateStatusByIdsTarget() {
    const ids = this.multipleSelection.map((i: any) => i.id);
    this.updateStatusByIds(ids, this.status);
    this.setStatusVisible = false;
  }

  // 修改状态
  private updateStatusByIds(ids: number[], isEnable: number) {
    const params = {
      ids,
      isEnable
    };
    updateStatus(params).then((res: any) => {
      if (res.code === 200) {
        this.getorderData();
      }
    });
  }

  // 修改号源
  private updateBatchByIds() {
    const params = {
      ids: this.multipleSelection.map((i: any) => i.id),
      num: this.batchNum
    };
    updateBatch(params).then((res: any) => {
      if (res.code === 200) {
        this.getorderData();
        this.setNumVisible = false;
      }
    });
  }

  private deleteOne(id: number) {
    this.deleteByIds([id]);
  }

  // 删除
  private deleteByIds(ids: number[]) {
    deleteBatch(ids).then((res: any) => {
      if (res.code === 200) {
        this.$message.success(res.msg);
        this.getorderData();
      } else {
        this.$message.error(res.msg);
      }
    });
  }

  // 搜索
  // public async search() {
  //   const value = { ...this.page, ...this.form };
  //   // const form = this.$changNull(value);
  //   // if (form.checkerName) {
  //   //   this.$set(form['jkglChecker'], 'checkerName', form.checkerName);
  //   // } else {
  //   //   this.$set(form['jkglChecker'], 'checkerName', null);
  //   // }
  //   console.log(value);

  //   const res = await getSchedule(this.$changNull(value));
  //   this.orderData = res.data.records;
  //   this.total = res.data.total;
  // }

  private submitForm(formName: string) {
    // 提交
    (this.$refs[formName] as any).validate((valid: boolean) => {
      if (valid) {
        this.saveItemByParams({
          ...this.ruleForm
        });
      } else {
        console.log("error submit!!");
        return false;
      }
    });
  }

  private saveItemByParams(params: any) {
    saveItem(params).then(res => {
      if (res.code === 200) {
        this.$message.success(res.msg);
        this.dialogVisible = false;
        this.getorderData();
      }
    });
  }

  private resetFormDia(formName: string) {
    (this.$refs[formName] as any).resetFields();
  }

  private addNew() {
    this.ruleForm = {
      // id:0,
      num: "",
      startDate: "",
      endDate: "",
      isEnable: 1,
      packageId: 0,
      personnelLevel: "不限",
      hospital: 'A',
      companyList: [
        //    {
        //   key:"1",
        //   companyId:'',
        //   number:0
        // }
      ]
    };
    this.fetchPackage()
    this.dialogVisible = true;
  }

  private deleteMul() {
    const ids = this.multipleSelection.map((i: any) => i.id);
    this.deleteByIds(ids);
  }

  /** 点击常看详情 */
  private async showDialog(row: any) {
    this.ruleForm = {
      ...row,
      startDate: row.date,
      endDate: row.date,
      packageId: 0 // 替代方案，暂时默认为1
    };
    this.dialogVisible = true;
  }

  /** 点击常看详情的关闭 */
  private canleDialog() {
    this.dialogVisible = false;
  }

  /** 点击常看详情的确定 */
  private confirmDialog() {
    this.dialogVisible = false;
  }
  //时间界面
  public dialogTime = false
  //时间数据
  public comForm: any = []
  //编辑或者保存
  public editor = true
  /*显示号源界面*/
  public viewSource(row: any) {
    this.dialogTime = true;
    console.log('输出当前的行数据', row)
    this.addForm.scheduleId = row.id
    const params = {
      scheduleId: row.id
    }
    //查询时间段
    this.selectTime(params);
  }

  //获取时间段数据
  public selectTime(params: any) {
    //selectTime
    selectTime(params).then(res => {
      console.log('输出参数列表', res)
      this.comForm = res.data;
    })
  }

  //删除时间段
  public deleteItem(data: any) {
    (this as any).$messageBox
      .confirm(`确定要删除该时间段吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        const parmar = [data.id]
        deleteTime(parmar).then(res => {
          if (res.code === 200) {
            this.$message.success("操作成功！")
          }
        })
      })
  }
  //更新人数
  public saveTime(data: any) {
    const parmar = {
      edTime: data.endTime,
      id: data.id,
      number: data.number,
      stTime: data.startTime
    }
    updataTime(parmar).then(res => {
      if (res.code === 200) {
        this.$message.success("操作成功！")
        this.dialogTime = false;
      }
    })
  }
  //提交表单
  public addForm = {
    edTime: '',
    scheduleId: '',
    number: '',
    stTime: ''
  }
  //新增时间段
  public addTime() {
    if (this.addForm.edTime === '' || this.addForm.stTime === '') {
      this.$message.warning("请填写完整时间段！")
      return
    }
    if (this.addForm.number === '') {
      this.$message.warning("请输入号源人数")
      return
    }
    const edTime = new Date(this.addForm.edTime);
    const edTimeOver = edTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });
    this.addForm.edTime = edTimeOver;
    const stTime = new Date(this.addForm.stTime);
    const stTimeOver = stTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });
    this.addForm.stTime = stTimeOver;
    addTime(this.addForm).then(res => {
      if (res.code === 200) {
        this.$message.success("操作成功！")
        //提交表单
        this.addForm = {
          edTime: '',
          scheduleId: '',
          number: '',
          stTime: ''
        }
        this.dialogTime = false;
      }
    })
  }
}
